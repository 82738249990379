/* Rating Box */


.star-rating {
    background-color: white !important;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid black;
}

.map {
    text-align: center;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    width: 270px;
    margin: 0px auto;
    padding: 3px;
}

.star-label {
    color: black;
    margin-right: 10px;
}


.star {
    color: purple;
    margin-right: 4px;
}


.star-radio {
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid purple;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
}

.star-radio:checked {
    background-color: purple;
}

.spacingClass {
    margin: 20px;
}

/* Range */

/* Filter.module.css */

.filter {
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 20px;
    width: 230px;
    margin: 0px auto;
}

.filter-label {
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.filter-range {
    display: flex;
    align-items: center;
}

.filter-range-label {
    flex: 1;
    text-align: center;
}

.filter-range-slider {
    flex: 6;
}

.filter-range-slider input[type="range"] {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    border-radius: 5px;
    background: #ccc;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
}

.filter-range-slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
}

.filter-range-slider input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
}

.filter-currency {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-weight: bold;
}


/* button */

.button button {
    background-color: #dbaf65;
    color: white;
    border: none;
    border-radius: 30px;
    height: 36px;
    width: 136px;
    font-size: 14px;
}

.buttonAvailiblity button {
    background-color: #BD00FF;
    color: white;
    border: none;
    border-radius: 30px;
    height: 36px;
    width: 200px;
    font-size: 14px;
}

.title {
    font-size: 36px;
    font-weight: 600;
}

.status {
    font-size: 14px;
    font-weight: 400;
}