@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  font-family: 'Inter', sans-serif !important;
}

.booking .react-international-phone-input {
  width: 100% !important;
  border-color: #BD00FF !important;
}

.booking .react-international-phone-country-selector button {
  border-color: #BD00FF !important;
}

.tour .react-international-phone-country-selector-button {
  height: 56px !important;
  width: 70px;
}

.tour .react-international-phone-input {
  height: 56px !important;
}

.tour .react-international-phone-input {
  width: 490px;
}

.tour .react-international-phone-input:focus {
  border: 2px solid #2196f3;
}

.media-card::-webkit-scrollbar {
  display: none;
}

.Popular-text {
  font-size: 71px !important;
  font-style: italic !important;
  position: relative;
  font-weight: 400 !important;
  top: 100px;
  color: #000000 !important;
  font-family: "Dancing Script" !important;
}

.next-icon-card {
  background-color: #DBB065 !important;
  color: black !important;
  border-radius: 15px !important;
  border: none !important;
  width: 40px !important;
  padding: 3px !important;

}

.prev-icon-card {
  background-color: #DBB065 !important;
  color: black !important;
  font-size: 15px !important;
  border: none !important;
  border-radius: 15px !important;
  width: 40px !important;
  padding: 3px !important;
}

.swiper-button-prev::after {
  content: "" !important;
}

.swiper-button-next::after {
  content: "" !important;
}

.hero-cards {
  background: "rgb(161 160 160)" !important;
  padding: 20px !important;
}

.card-hero img {
  border: 3px solid #BD00FF !important;
  border-radius: 15px !important;
  width: 220px !important;
  height: 159px !important;
}

.card-title-heading {
  color: black !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
}

.wavy-line-upper {
  --wave-w: 200px;
  --wave-h: 100px;
  display: flex;
  height: calc(var(--wave-h) / 2);
  margin-left: -200px;
  overflow: hidden;
  /* 	background: #1976D2; */
}

.wavy-line-upper div {
  width: var(--wave-w);
  min-width: var(--wave-w);
  height: var(--wave-h);
  margin-left: calc(var(--wave-w) * 0.75);
  border-radius: 100px 100px 0 0;
  /* 	background: #FFF; */
  box-sizing: border-box;
  border: dotted 2px #DBB065;
  border-bottom: 0;
  overflow: hidden;
}

.wavy-line-downer {
  --wave-w: 200px;
  --wave-h: 100px;
  display: flex;
  margin-left: -25px;
  background: #FFF;
  overflow: hidden;
}

.wavy-line-downer div {
  width: var(--wave-w);
  min-width: var(--wave-w);
  height: var(--wave-h);
  position: relative;
  top: calc(var(--wave-h) * -0.42);
  margin-left: calc(var(--wave-w) * 0.75);
  border-radius: 0 0 100px 100px;
  box-sizing: border-box;
  border: dotted 2px #DBB065;
  border-top: 0;
}