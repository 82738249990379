.title {
    font-size: 36px;
    color: black;
    font-weight: 500;
}

.sideCardOne {
    display: flex;
    align-items: center;
}

.sideCardTwo {
    background-color: #BD00FF;
    color: white;
    display: flex;
    align-items: center;
}

.name {
    flex: 1;
}

/* Room Heading */

.RoomsHeading span{
    font-size: 34px;
    font-weight: 600px;
}