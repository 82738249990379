.customTourBg {
    position: relative! important;
    height: 641px! important; 
    overflow: hidden! important;
  }
  
  .textImageContainer {
    position: relative! important;
    width: 100%! important;
    height: 100%! important;
  }
  
  .mainBackgroundImage {
    width: 100%! important;
    height: 100%! important;
    object-fit: cover! important;
    position: absolute! important;
    top: 0! important;
    left: 0! important;
    z-index: 1! important; 
  }
  
  .centeredImage {
    font-size: 100px !important;
    font-weight: 900;
    color: white;
    position: absolute! important;
    top: 50%! important;
    left: 50%! important;
    transform: translate(-50%, -50%)! important;
    max-width: 100%! important;
    max-height: 100%! important;
    z-index: 2! important; 
  }
  