.ant-popover {
    background-color: #d9d9d9 !important;
    border-radius: 8px;
}

.ant-popover-content,
.ant-popover-inner {
    background-color: #d9d9d9 !important;
    border-radius: 8px;
}

.ant-popover-arrow::after {
    background-color: #d9d9d9 !important;
}