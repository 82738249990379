* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

.customBgMain .slick-dots {
    position: relative;
    top: -80px;
}

.visaHeading {
    font-family: Inter;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -0.02em;
}

.visaSubHeading {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: -0.02em;
    text-align: left;
}

.subSubHeading {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: left;
}