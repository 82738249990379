.header {
    display: flex;
    gap: 10px;
}

.tag {
    background-color: #b89258;
    color: white;
    padding: 5px;
    border-radius: 5px;
    align-items: center;
    width: max-content !important;
}

.route {
    flex: 1;
    border: 1px solid black;
    padding: 5px;
    border-radius: 5px;
    background-color: white;
}

.route span {
    font-weight: 700;
}

/* ticket body */

.ticket_body {
    background-color: white;
    border-radius: 5px;
    border: 1px solid black;
    padding: 10px;
    align-items: center;

}

.arrow p {
    text-align: center;
}

.Ticket_details {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.logo,
.detail1,
.detail2,
.button {
    text-align: center;
    padding: 10px;
}

.button button {
    background-color: #b89258;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 5px;
}

.Ticket_details .departureTime .time span {
    font-size: 30px;
}

.time,
.airport,
.day {
    padding: 5px;
}

/* Price Details */

.priceDetailsHeading h3 {
    font-size: 20px;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
}

.table {
    border: 1px solid black;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
}

.th {
    border-top: none !important;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray !important;
}

.td {
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
}

.td:last-child,
.th:last-child {
    border-right: none !important;
    border-left: none !important;
}

.th:first-child,
.td:first-child {
    border-left: none !important;
}

.td-total,
.th-total {
    border-bottom: none;
}

.totalPrice h1 {
    float: right;
}

/* Pessenger Info */

.pessengerInfo {
    display: flex;
    justify-content: space-between;
}

.pessengerInfoDiv,
.pessengerContactDiv {
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    height: 350px;
}

.pessengerInfoForm label {
    font-weight: 700;
    padding: 10px;
    padding-top: 20px;
}

.pessengerInfoForm input {
    background-color: #e0e0e0;
    border-radius: 5px;
    border: 1px solid black;
    height: 30px;
    padding-top: 20px;
}

.pessengerInfoGender p {
    font-weight: 700;
}

.pessengerInfoPassport p {
    font-weight: 700;
}

.pessengerInfoGender select {
    width: 200px;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 5px;
    border: 1px solid black;
    color: #be23ff;
}

.pessengerInfoPassport select {
    width: 100%;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 5px;
    border: 1px solid black;
    color: #be23ff;
}

.pessengerContactDiv label {
    font-weight: 700;
    padding: 10px;
}

.pessengerContactDiv input {
    background-color: #e0e0e0;
    border-radius: 5px;
    border: 1px solid black;

}

.radioGroup {
    display: flex;
    flex-direction: column;
}

.radioLabel {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 500;
}

.radioInput {
    margin-right: 5px;
}

.radioInput:checked+.radioLabel::before {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: purple;
    margin-right: 6px;
}

.radioInput:checked+.radioLabel::after {
    content: '\2022';
    font-size: 17px;
    color: transparent;
}


.PaymentInfo {
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    padding: 20px;
    height: 250px;
}

.cardNameInput,
.cardNumberInput,
.expiryDateInput,
.yearDropdown,
.securityCodeInput {
    padding-top: 40px;
}


.cardNameInput input,
.cardNamePessenger_info input,
.cardNumberInput input,
.expiryDateInput input,
.yearDropdown select,
.securityCodeInput input {
    background-color: #e0e0e0;
    color: purple;
    height: 30px;
}

.radioButton {
    scale: 1.5 !important;
}

/* Extra CSS */

.underLineCSS {
    font-weight: 700;
    color: black;
    border-bottom: 2px solid purple;
    cursor: pointer;
}

.BuyNowButton button {
    background-color: #be23ff;
    color: white;
    border-radius: 20px;
    border: none;
    width: 166px;
    height: 37px;
    font-size: 16px;
    font-weight: 700;
}