.cutomerReview {
    border: none;
    border-right: 1px solid #b1c5b1;
}

@media screen and (max-width: 758px) {
    .cutomerReview {
        border: none !important;
    }

    .review {
        font-size: 14px !important;
    }
}