.customBookNowBtn {
    background-color: #DBB065 !important;
    color: white !important;
    padding: 10px 25px !important;
    border-radius: 12px !important;
}

.CustomCheckAvailablebtn {
    background-color: #BD00FF !important;
    border-radius: 12px !important;
    padding: 7px !important;
}

.no-hover:hover {
    background: #be23ff !important;
}

.My-Btn-Style-Whatsapp {
    width: 150px !important;
    height: 50px !important;
    background-color: #25D366 !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3) !important;
}

.icon-style-size {
    font-size: 20px !important;
    align-items: center !important;
}

.My-Btn-Style-Call {
    width: 150px !important;
    height: 50px !important;
    background-color: transparent !important;
    border: 1px solid rgb(65, 64, 64) !important;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3) !important;
}

.socialbtns {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}