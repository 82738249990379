.slotBooker {
    max-width: 60%;
    border: 1px solid black;
    border-radius: 6px;
    height: max-content;
}

.customDropdowm {
    background-color: #dbb065;
    display: inline-flex;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
    border: none;
    outline: none;
    width: 100% !important;
    height: 40px;
    font-weight: bolder;
}

.customDiv {
    background-color: #dbb065;
    align-items: center;
    padding: 7px;
    border-radius: 15px;
    border: none;
    outline: none;
    width: 100% !important;
    height: 40px;
    font-weight: bolder;
    color: black;
    cursor: pointer;
}

.customDropdowmMenu {
    background-color: #dbb065;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
    border: none;
    outline: none;
    width: 100% !important;
    height: 40px;
    font-weight: bolder;
    color: black;
    font-size: 15px;
    flex-wrap: nowrap;
    cursor: pointer;
}

.customDropdowm option {
    background-color: white;
    display: inline-flex;
    align-items: center;
    padding: 8px;
    border-radius: 15px;
    border: none;
    outline: none;
    width: 100% !important;
}

.CheckAvailiblityButton {
    background-color: #be23ff;
    color: white;
    border-radius: 25px;
    height: 50px;
    width: 100%;
    border: none;
    font-size: 20px;
}

.CitySlot {
    max-width: 60%;
    border: 1px solid black;
    border-radius: 6px;
    height: max-content;
}

.startingTimeButtonActive {
    background-color: #c1c4cf;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-weight: bold;
}

.startingTimeButtonInactive {
    background-color: #c1c4cf;
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: black;
    font-weight: bold;
}

.CardFooter {
    background: #dbb065;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.CardFooter h5 {
    font-weight: bolder;
}

.CardFooter button {
    background-color: #be23ff;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 30px;
    padding: 15px;
    width: 300px;
}


.counter {
    display: flex;
    justify-content: space-around;
}

.tableMain {
    border: 1px solid gray;
    border-radius: 5px;
}

input[type="radio"] {
    position: absolute;
    opacity: 0;
}

input[type="radio"]+label::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-radius: 50%;
    border: 2px solid #be23ff;
}

input[type="radio"]:checked+label::before {
    background-color: #be23ff;
}

.ant-picker-cell-inner .ant-picker-calendar-date .ant-picker-calendar-date-today {
    background-color: #be23ff !important;
}

.ant-picker-cell-inner .ant-picker-calendar-date {
    background-color: #be23ff !important;

}


.MediaCard {
    background-color: #be23ff !important;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 30px;
    padding: 10px;
}